/* Reset CSS */
* {
  padding: 0px;
  margin: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*:focus {
  outline: 0;
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'GeneralSans-Regular', sans-serif;
  min-height: 100vh;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

a,
a:link {
  text-decoration: none;
  outline: none;
}

p {
  padding: 2px 0;
  margin: 0;
}

a:focus {
  outline: none !important;
}

:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

/* Reset CSS */

/* Fonts */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: var(--black);
  font-family: 'GeneralSans-Medium';
}

h1 {
  font-size: 64px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

h4 {}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 15px;
}

/* Fonts */

.container {
  max-width: 1760px !important;
}

.container.sm {
  max-width: 1280px !important;
}

.site-link {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  background: var(--primary);
  color: var(--white);
  font-size: 18px;
  font-family: 'GeneralSans-Medium';
  border-radius: 50px;
  padding: 10px 30px;
  border: 1px solid var(--primary);
  transition: all 0.5s ease;
}

.site-link::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background: var(--secondary);
  transition: all 0.5s ease;
}

.site-link:hover::before {
  width: 100%;
}

.site-link span {
  position: relative;
  z-index: 1;
}

/* .site-link:hover{ background: var(--secondary);} */
.site-link.full {
  width: 100%;
  display: block;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.site-link.sm {
  padding: 8px;
  font-size: 14px;
}

.site-link.bdr {
  background: var(--white);
  color: var(--primary);
}

.site-link.bdr:hover {
  color: var(--white);
}

.site-link.btn-sm {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.logo img {
  width: 220px;
  max-height: 80px;
}

.site-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  transition: all 0.5s ease;
  background: #FAFAFA;
}

.header-main {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.site-nav {
  padding-left: 150px;
}

.site-nav>ul>li {
  display: inline-block;
  margin-left: 30px;
  position: relative;
}

.site-nav>ul>li>a {
  display: inline-block;
  color: #263238;
  font-size: 15px;
  position: relative;
  padding: 6px 0;
  font-family: 'GeneralSans-Medium';
}

.site-nav>ul>li>a.active {
  color: var(--primary);
  font-family: 'GeneralSans-Medium';
}

.site-nav>ul>li>a.active::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 20px;
  height: 3px;
  background: var(--primary);
  border-radius: 10px;
}

.site-nav>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
}

.site-nav>ul>li>ul {
  position: absolute;
  width: 200px;
  left: 0;
  top: 100%;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.site-nav>ul>li>ul>li {
  display: block;
}

.site-nav>ul>li>ul>li>a {
  display: flex;
  align-items: center;
  color: #263238;
  font-family: 'GeneralSans-Medium';
  padding: 8px 16px;
  font-size: 14px;
}

.site-nav>ul>li>ul>li>a:hover {
  color: var(--primary);
}

.front-page .site-nav>ul>li>a {
  color: var(--white);
  font-family: 'GeneralSans-Light';
}

.front-page .site-nav>ul>li:hover>a {
  color: var(--primary);
}

.front-page .site-nav>ul>li>a.active {
  color: var(--primary);
  font-family: 'GeneralSans-Medium';
}

.front-page .profile-drop button {
  color: var(--white) !important;
}

.logo-light {
  display: none;
}

.front-page .logo-light {
  display: block;
}

.front-page .logo-dark {
  display: none;
}

.front-page .site-header {
  background: none;
}

.front-page .site-header.fixed {
  background: var(--secondary);
}

.profile-drop button {
  display: flex;
  align-items: center;
  color: #263238 !important;
  outline: 0;
  box-shadow: none;
  border: none;
}

.profile-drop button:focus {
  outline: 0;
  box-shadow: none;
  border: none;
}

.profile-drop button::after {
  display: none;
}

.pro-pic {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 5px;
  position: relative;
}

.pro-pic img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 100%;
  border: 2px solid var(--white);
}

.pro-stat {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  position: absolute;
  right: -3px;
  bottom: 0;
  background: #8399E9;
  border: 2px solid var(--white);
}

.profile-drop .dropdown-menu {
  width: 236px;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  left: inherit !important;
  right: 0 !important;
  border: none;
}

.profile-drop .dropdown-menu ul li a {
  display: flex;
  align-items: center;
  color: #263238;
  font-family: 'GeneralSans-Medium';
  padding: 8px 16px;
  font-size: 15px;
}

.profile-drop .dropdown-menu ul li a span {
  display: inline-block;
  padding-right: 8px;
}

.profile-drop .dropdown-menu ul li a svg {
  font-size: 24px;
}

.profile-drop .dropdown-menu ul li a:hover {
  color: var(--primary);
}

.hero-banner {
  min-height: 600px;
  height: calc(100vh - 130px);
  /* background: url('../src/assets/images/banner.jpg') top center no-repeat;
  background-size: cover; */
  display: flex;
  align-items: center;
  position: relative;
}

.hero-banner::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, #000 0%, rgba(125, 185, 232, 0) 100%);
}

.hero-banner-video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.hero-banner-video iframe {
  position: absolute;
  width: 100%;
  height: calc(100vw/1.77);
  left: 0;
  top: 0;
  transform: translateY(-70px);
}

.hero-banner-in {
  width: 100%;
  position: relative;
  z-index: 2;
}

.hero-banner-txt {
  max-width: 570px;
  color: var(--white);
}

.hero-banner-txt h6 {
  position: relative;
  font-size: 20px;
  font-family: 'GeneralSans-Medium';
  padding: 0 0 0 50px;
  color: var(--white);
}

.hero-banner-txt h6::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 4px;
  background: var(--white);
}

.hero-banner-txt h1 {
  font-family: 'GeneralSans-Semibold';
  padding-top: 10px;
  color: var(--white);
}

.hero-banner-txt h1 span {
  color: var(--primary);
}

.hero-banner-txt p {
  font-size: 20px;
  font-family: 'GeneralSans-Medium';
  padding: 15px 0 0 0;
}

.banner-link {
  transition: all 0.5s ease;
  display: inline-block;
  color: #FFF;
  font-size: 18px;
  font-family: 'GeneralSans-Medium';
  background: var(--primary);
  border-radius: 12px;
  padding: 12px 22px;
  margin-top: 20px;
}

.banner-link:hover {
  opacity: 0.8;
}

.banner-link svg {
  font-size: 28px;
  display: inline-block;
  transform: translateY(-2px);
}

.stats {
  display: flex;
  align-items: center;
  margin: 0 -9px;
  position: relative;
  z-index: 2;
  margin-top: -120px;
}

.stats-single {
  padding: 0 9px;
  width: 20%;
  transition: all 0.5s ease;
}

.stats-single:hover {
  transform: translateY(-10px);
}

.stats-in {
  height: 188px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(10px);
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.stats-in>div {
  width: 100%;
}

.stats-in.forGraph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.graph-img {
  text-align: center;
}

.graph-txt {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.graph-txt p {
  color: var(--black);
  font-family: 'GeneralSans-Medium';
}

.graph-txt h6 {
  font-size: 36px;
  color: var(--black);
  font-family: 'GeneralSans-Medium';
}

.stats-in.for-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.content-sc-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-sc-top p {
  color: var(--black);
  font-family: 'GeneralSans-Medium';
}

.content-sc-top h6 {
  font-size: 36px;
  font-family: 'GeneralSans-Medium';
}

.content-sc-bottom {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.content-sc-bottom p {
  font-size: 11px;
}

.stats-in.forClients {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.client-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forClients h6 {
  font-size: 24px;
  color: var(--black);
  font-family: 'GeneralSans-Medium';
}

.client-top svg {
  font-size: 30px;
  color: var(--black);
}

.client-bottom {
  width: 100%;
}

.client-img {
  display: inline-block;
  position: relative;
}

.number-client {
  position: absolute;
  right: -36px;
  top: 0;
  width: 53px;
  height: 53px;
  background: var(--white);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  font-family: 'GeneralSans-Medium';
}

.client-img ul li {
  display: inline-block;
  width: 53px;
  height: 53px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 0 0 -12px;
}

.client-img ul li:first-child {
  margin-left: 0;
}

.client-img ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stats-single.bg-1 .stats-in {
  position: relative;
  overflow: hidden;
}

.stats-single.bg-1 .stats-in::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  background: url('../src/assets/images/shape-1.png') no-repeat;
  width: 81px;
  height: 91px;
}

.stats-single.bg-2 .stats-in {
  position: relative;
  overflow: hidden;
}

.stats-single.bg-2 .stats-in::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  background: url('../src/assets/images/shape-2.png') no-repeat;
  width: 81px;
  height: 91px;
}

.sec-intro {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.sec-intro p {
  padding-top: 15px;
}

.recent-video {
  margin-top: 150px;
}

.video-listing-single {
  border-radius: 30px;
  background: var(--white);
  margin-top: 30px;
  transition: all 0.5s ease;
}

.video-listing-single:hover {
  /* transform: translateY(-10px); */
}

.video-listing-single:hover .video-listing-img img {
  transform: scale(1.1);
}

.video-listing-img {
  height: 328px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  position: relative;
}

.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: var(--white);
  font-size: 80px;
}

.video-listing-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
}

.video-listing-bottom {
  display: flex;
  align-items: center;
  padding: 20px 35px;
  justify-content: space-between;
}

.video-listing {
  padding-top: 20px;
}

.video-listing-action button {
  display: flex;
  align-items: center;
  color: var(--black) !important;
  outline: 0;
  box-shadow: none;
  border: none;
  padding: 0;
  font-size: 30px;
  padding-right: 0 !important;
}

.video-listing-action button:focus {
  outline: 0;
  box-shadow: none;
  border: none;
}

.video-listing-action button::after {
  display: none;
}

.video-listing-action .dropdown-menu {
  width: 160px;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  left: inherit !important;
  right: 0 !important;
  border: none;
}

.video-listing-action .dropdown-menu ul li a {
  display: flex;
  align-items: center;
  color: #263238;
  font-family: 'GeneralSans-Medium';
  padding: 8px 16px;
  font-size: 14px;
}

.video-listing-action .dropdown-menu ul li a:hover {
  color: var(--primary);
}

.video-listing-action .dropdown-menu ul li a span {
  display: inline-block;
  padding-right: 8px;
}

.video-listing-action .dropdown-menu ul li a svg {
  font-size: 20px;
}

.load-more {
  margin-top: 46px;
  text-align: center;
}

.image-block-txt h6 {
  position: relative;
  font-size: 20px;
  font-family: 'GeneralSans-Medium';
  padding: 0 0 0 50px;
  color: var(--black);
}

.image-block-txt h6::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 3px;
  background: var(--black);
}

.image-block-txt h2 {
  padding-top: 8px;
}

.image-block-txt p {
  padding: 20px 0 0 0;
}

.image-block {
  padding-top: 50px;
}

.image-block-single {
  margin-top: 120px;
}

.image-block-single:nth-child(even) [class*='col-'] {
  order: 2;
}

.image-block-single:nth-child(even) [class*='col-']+[class*='col-'] {
  order: 1;
}

.image-block-single:nth-child(even) .image-block-img {
  text-align: right;
}

.webinar {
  background: url('../src/assets/images/pattern.png') top center no-repeat;
  background-size: 100% auto;
  padding: 380px 0;
  padding-bottom: 200px;
  margin-top: -100px;
}

.webinar .container {
  transform: translateY(60px);
}

.site-footer {
  background: var(--lighter-blue);
  color: #263238;
  font-family: 'GeneralSans-Medium';
}

.profileLoader {
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.site-footer-in {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-footer ul li {
  display: inline-block;
  margin-left: 15px;
}

.site-footer ul li:first-child {
  margin-left: 0;
}

.site-footer ul li a {
  display: inline-block;
  color: #263238;
}

.site-footer ul li a:hover {
  color: blue;
}

.footer-single {
  text-align: center;
}

.footer-single a {
  color: blue;
}

.footer-single p {
  color: #263238;
}

.footer-logo img {
  width: 180px;
}


.mobClick {
  display: none;
  width: 24px;
  height: 16px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  left: 15px;
  top: 18px;
  z-index: 999;
  margin-left: 20px;
}

.mobClick span {
  border-radius: 0 !important;
  margin-left: 0 !important;
  position: absolute;
  opacity: 1;
  left: 0;
  width: 24px;
  height: 3px;
  background: #FFFFFF !important;
  display: block;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.front-page .mobClick span {
  background: #FFFFFF !important;
}

.mobClick span {
  background: var(--secondary) !important;
}

.mobClick.open span {
  background: #FFFFFF !important;
}

.mobClick span:nth-child(2) {
  top: 7px;
}

.mobClick span:nth-child(3) {
  top: 14px;
}

.mobClick.open span:nth-child(1) {
  top: 7px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mobClick.open span:nth-child(2) {
  opacity: 0;
  left: -20px;
}

.mobClick.open span:nth-child(3) {
  top: 7px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.mobClick.open {
  position: fixed;
  left: calc(100vw - 60px);
  top: 15px;
  z-index: 99999;
}


.page-wrap {
  min-height: calc(100vh - 80px);
  padding: 120px 0 50px 0;
}

h1.page-title {
  font-size: 32px;
  font-family: 'GeneralSans-Medium';
}

.content-search {
  margin-top: 25px;
}

.inp {
  width: 100%;
  background: var(--white);
  border: 1px solid var(--white);
  border-radius: 50px;
  padding: 18px 35px;
  font-family: 'GeneralSans-Medium';
}

select.inp {
  appearance: none;
  background: url('../src/assets/images/down.png') right 20px center no-repeat #FFF;
}

.inp:focus {
  border: 1px solid var(--secondary);
}

.input-wrap.with-icon {
  position: relative;
}

.inp-icon {
  position: absolute;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--black);
}

.input-wrap.with-icon .inp {
  padding-left: 70px;
}

.title-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-block-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-title {
  background: var(--white);
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 17px;
  padding: 0 30px;
}

.edit-title p {
  color: var(--black);
  font-family: 'GeneralSans-Medium';
}

.edit-title span {
  display: inline-block;
  padding-left: 15px;
}

.editor-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.editor-left-in {
  width: 480px;
  background: var(--white);
  border-radius: 20px;
  height: auto;
  background: linear-gradient(0deg, #F0F5FF 0%, #F0F5FF 100%), #FFF;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.editor-left-in::after {
  content: '';
  position: absolute;
  width: 330px;
  height: 100%;
  right: 0;
  top: 0;
  background: var(--white);
}

.editor-right {
  width: calc(100% - 480px);
  padding-left: 30px;
}

.editor {
  height: 422px;
  background: #eddbc5;
  border-radius: 20px;
}

.editor-tab {
  display: flex;
  justify-content: space-between;
}

.editor-tab-left {
  width: 150px;
  margin: 0;
  padding: 30px 0;
}

.editor-tab .tab-content {
  width: calc(100% - 150px);
  padding-left: 12px;
  position: relative;
  z-index: 1;
  padding: 18px;
  padding-top: 32px;
}

.editor-tab-left .nav-item {
  margin: 4px 0;
}

.editor-tab-left .nav-link {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 12px;
  color: #767676;
  font-size: 15px;
  font-family: 'GeneralSans-Medium';
}

.editor-tab-left .nav-link.active {
  background: var(--white);
  color: var(--black);
}

.editor-tab-left .nav-link span {
  display: inline-block;
  padding-right: 8px;
  transform: translateY(-1px);
}

.editor-tab-left .nav-link span svg {
  width: 20px;
  height: 20px;
}

.cont-search {
  position: relative;
}

.cont-search.with-icon .cont-inp {
  padding-left: 42px;
}

.cont-inp {
  width: 100%;
  border: 1px solid var(--white);
  border: 0.5px solid #dfdfdf;
  border-radius: 50px;
  padding: 9px 20px;
  font-size: 14px;
}

.cont-search span {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1px;
}

.clip-cont {
  margin-top: 20px;
}

.input {
  width: 100%;
  border: 0.5px solid #dfdfdf;
  border-radius: 15px;
  padding: 9px 20px;
  font-size: 14px;
}

.input.solid {
  background: #FAFAFA;
  border: 1px solid #dfdfdf;
}

.inp-wrap label {
  font-size: 14px;
  color: var(--body-font-color);
  display: block;
  padding-bottom: 5px;
}

textarea.input {
  height: 120px;
}

select.input {
  height: 41px;
  padding-top: 0;
  padding-bottom: 0;
  appearance: none;
  background: url('../src/assets/images/down.png') right 20px center no-repeat #FFF;
}

select.input.solid {
  background: url('../src/assets/images/down.png') right 20px center no-repeat #FAFAFA;
}

.inner-tab {
  padding-top: 14px;
}

.inner-tab .tab-content {
  width: 100%;
  padding: 15px 0 0 0;
}

.inner-tab .nav {
  display: flex;
  align-items: center;
}

.inner-tab .nav-item {
  margin-left: 15px;
}

.inner-tab .nav-item:first-child {
  margin-left: 0;
}

.inner-tab .nav-link {
  padding: 0;
  font-size: 14px;
  color: var(--body-font-color);
}

.inner-tab .nav-link.active {
  color: #263238;
  font-family: 'GeneralSans-Medium';
}

.list-images ul {
  display: flex;
  align-items: flex-start;
  margin: 0 -5px;
  flex-wrap: wrap;
}

.list-images ul li {
  width: 50%;
  padding: 0 5px;
  margin-top: 10px;
}

.list-images-single {
  position: relative;
  height: 70px;
  overflow: hidden;
  border-radius: 10px;
}

.list-images-single img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-box {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  background: #FAFAFA;
  text-align: center;
}

.upload-box svg {
  font-size: 22px;
}

.upload-box p {
  font-size: 14px;
}

.upload-box input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 2;
}

.upload-box.large {
  height: 250px;
}

.upload-box-large {
  color: #618FCA;
}

.upload-box-large svg {
  font-size: 50px;
}

.upload-box-large p {
  font-size: 17px;
}

.font-heading ul li {
  background: var(--off-white);
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  padding: 11px 5px;
  margin-top: 8px;
}

.font-heading ul li:first-child {
  margin-top: 0;
}

.box-cont {
  margin-top: 15px;
}

.box-cont label {
  display: block;
  font-size: 14px;
  padding-bottom: 5px;
}

.box-cont-in {
  background: var(--off-white);
  border-radius: 15px;
  padding: 15px;
}

.txt-size {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txt-size span {
  color: var(--black);
  font-family: 'GeneralSans-Medium';
}

.txt-size span:last-child {
  font-size: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #618FCA;
  cursor: pointer;
  border-radius: 100%;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #618FCA;
  cursor: pointer;
  border-radius: 100%;
}

.slidecontainer {
  width: calc(100% - 80px);
}

.color-select {
  display: flex;
  align-items: center;
  font-family: 'GeneralSans-Medium';
}

.selected-color {
  width: 23px;
  height: 23px;
  border-radius: 5px;
  background: #18185E;
}

.type-settings ul li {
  display: inline-block;
  font-size: 24px;
  line-height: 18px;
  margin-left: 10px;
  cursor: pointer;
}

.type-settings ul li.active {
  color: var(--black);
}

.type-settings ul li:first-child {
  margin-left: 0;
}

.quote-lists ul li {
  background: var(--off-white);
  border-radius: 15px;
  font-size: 14px;
  padding: 10px;
  margin-top: 12px;
  color: var(--black);
}

.list-delete {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 20px;
  height: 20px;
  background: var(--primary);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 13px;
  cursor: pointer;
  opacity: 0;
}

.list-delete:hover {
  background: red;
}

.list-images-single:hover .list-delete {
  opacity: 1;
}

.img-crop {
  position: absolute;
  left: 6px;
  top: 6px;
  width: 20px;
  height: 20px;
  background: var(--white);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  font-size: 13px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.5s ease;
}

.list-images-single:hover .img-crop {
  opacity: 1;
}

.duration {
  position: absolute;
  left: 8px;
  bottom: 6px;
  color: var(--white);
  font-size: 14px;
}

.audio-list ul li {
  display: flex;
  align-items: center;
  position: relative;
  background: var(--off-white);
  border-radius: 15px;
  font-size: 14px;
  padding: 8px 15px;
  margin-top: 12px;
}

.audio-list ul li:first-child {
  margin-top: 0;
}

.audio-list-left {
  width: 30px;
  text-align: center;
  font-size: 20px;
}

.audio-txt {
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black);
}

.audio-dur {
  color: var(--black);
  font-size: 13px;
}

.del-audio {
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--lighter-blue);
  color: var(--black);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 18px;
  opacity: 0;
  transition: all 0.5s ease;
}

.audio-list ul li:hover .del-audio {
  opacity: 1;
}

.editor-settings {
  border-radius: 20px;
  background: var(--white);
  padding: 8px;
  margin-top: 10px;
}

.editor-bar {
  border-radius: 12px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--off-white);
  padding: 0 15px;
}

.editor-bar>div {
  flex: 1;
}

.editor-bar-left ul li {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}

.editor-bar-left ul li:first-child {
  margin-left: 0;
}

.editor-bar-left ul li:hover {
  color: var(--black);
}

.editor-bar-mid {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-bar-mid span {
  padding: 0 5px;
  font-size: 22px;
  display: flex;
}

.editor-bar-right {
  text-align: right;
  font-size: 20px;
}

.image-editor {
  position: relative;
}

.video-slide {
  margin-top: 5px;
  overflow: auto;
  display: flex;
  padding: 0 0 15px 0;
}

.ck-editor__editable {
  border: 1px solid #dfdfdf !important;
  border-top: 0 !important;
  box-shadow: none !important;
}

.video-slide::-webkit-scrollbar {
  height: 4px;
}

.video-slide::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.video-slide::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

.video-slide::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
  border-radius: 10px;
}

.quote-lists ul::-webkit-scrollbar {
  width: 4px;
}

.quote-lists ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.quote-lists ul::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

.quote-lists ul::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
  border-radius: 10px;
}

.quote-lists ul {
  padding-right: 6px;
}

.quote-lists {
  margin-right: -15px;
}

.video-slide ul {
  display: flex;
  align-items: center;
  margin: 0 16px;
  white-space: nowrap;
}

/* .video-slide ul li {
  padding: 0 8px;
  width: 150px;
} */

.video-slide-single {
  width: 100%;
  height: 82px;
  border-radius: 16px;
  overflow: hidden;
}

.video-slide-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.addSlide {
  width: 150px;
  height: 82px;
  border-radius: 16px;
  border: 1px solid #E0E0E0;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
}

.audio-bar {
  margin-top: 20px;
  border-radius: 12px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--light-blue);
  padding: 0 15px;
}

.render-bar {
  margin-top: 20px;
  text-align: right;
}


.client-single {
  border-radius: 30px;
  background: var(--white);
  padding: 40px 30px;
  margin-top: 30px;
  text-align: center;
  position: relative;
}

.edit-report {
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  right: 30px;
  top: 20px;
}

.client-single.alt {
  padding: 0;
  margin: 0;
  position: relative;
}

.client-single h3 {
  padding-top: 20px;
}

.client-imgN {
  width: 127px;
  height: 127px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.client-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.client-social {
  margin-top: 20px;
}

.client-social ul li {
  display: inline-block;
  margin: 0 10px;
  margin-top: 13px;
}

.client-social ul li img {
  width: 33px;
}

.client-social ul li.inactive-social img {
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.5;
}

.client-btn {
  margin-top: 30px;
}

.client-btn .site-link {
  margin: 0 5px;
}

.report-date {
  position: absolute;
  right: 0;
  top: -15px;
}

.content-search .site-link {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.cont-box-group {
  margin-top: 30px;
}

.cont-box {
  border-radius: 30px;
  background: var(--white);
  padding: 60px;
  margin-top: 30px;
}

.profilePic {
  width: 127px;
  height: 127px;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
}

.profilePic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.profilePic-up {
  width: 44px;
  height: 44px;
  background: var(--primary);
  border-radius: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 20px;
}

.profilePic-up input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 2;
}

.common-input-wrap {
  margin-top: 30px;
}

.common-input {
  width: 100%;
  border: 1px solid var(--off-white);
  background: var(--off-white);
  border-radius: 16px;
  padding: 17px 30px;
}

textarea.common-input {
  height: 162px;
}

.common-input:focus {
  border: 1px solid var(--secondary);
}

.common-input-wrap label {
  display: block;
  padding-bottom: 8px;
}

.common-input.large {
  height: 300px;
}

select.common-input {
  appearance: none;
  background: url('../src/assets/images/down.png') no-repeat right 25px center var(--off-white);
}

.image-editor {
  position: relative;
  /* margin-top: 30px; */
}

.image-editor-img {
  /* height: 490px; */
}

.image-editor-img .canvas-container {
  margin: 0 auto;
}

.image-editor-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.image-editor .editor-bar {
  /* position: absolute; */
  width: calc(100% - 60px);
  /* left: 0; */
  /* bottom: 30px; */
  /* margin: 0 30px; */
}

.bottom-btn-group {
  text-align: right;
  padding-top: 50px;
}

.text-editor-single {
  border-radius: 20px;
  background: var(--white);
  padding: 30px;
  margin-top: 30px;
}

.text-editor-single h5 {
  font-size: 20px;
}

.text-editor-single p {
  font-size: 16px;
  padding: 15px 0 0 0;
}

.text-editor-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.text-editor-bottom ul li {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}

.theme-table {
  margin: 0;
}

.theme-table tr th {
  border: none;
  color: #8A8A8A;
  font-weight: 600;
  white-space: nowrap;
  padding-bottom: 30px;
}

.theme-table tr td {
  color: #000000;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
  border: none;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
}

.table-highlight {
  color: #3744E5;
}

.table-tag {
  display: inline-block;
  background: #F6F6F6;
  padding: 4px 20px;
  border-radius: 15px;
  color: #8A8A8A;
}

.theme-table .site-link {
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 5px;
}

.thumb-image {
  display: inline-block;
  width: 86px;
  height: 86px;
  border: 1px solid #D9D9D9;
  border-radius: 12px;
  overflow: hidden;
}

.thumb-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.smBtn {
  cursor: pointer;
  min-width: 30px;
  height: 30px;
  background: rgba(251, 200, 199, 0.35);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #FF8585;
  margin: 0 4px;
}

.smBtn.edit {
  background: rgba(170, 207, 91, 0.26);
  color: #AACF5B;
}

.smBtn.text {
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
}

.theme-modal.xl .modal-dialog {
  max-width: 768px;
}

.theme-modal .modal-content {
  border-radius: 20px;
  border: none;
  box-shadow: none;
}

.theme-modal .modal-body {
  padding: 40px;
}

.theme-modal.remotion-preview_16X9.xl .modal-dialog {
  max-width: 1080px !important;
}


.theme-modal.remotion-preview_16X9 .modal-content {
  height: 700px !important;
}

.theme-modal.remotion-preview_16X9 .modal-body {
  padding: 12px !important;
}

.theme-modal.remotion-preview_1X1 .modal-dialog {
  max-width: 715px !important;
}


.theme-modal.remotion-preview_1X1 .modal-content {
  height: 798px !important;
}

.theme-modal.remotion-preview_1X1 .modal-body {
  padding: 12px !important;
}

/* 9X16 */
.theme-modal.remotion-preview_9X16 .modal-dialog {
  max-width: 640px !important;
}


.theme-modal.remotion-preview_9X16 .modal-content {
  height: 820px !important;
}

.theme-modal.remotion-preview_9X16 .modal-body {
  padding: 12px !important;
}





.modal-tab .nav-item {
  margin-left: 20px;
}

.modal-tab .nav-item:first-child {
  margin-left: 0;
}

.modal-tab .nav-link {
  padding: 0 0 5px 0;
  position: relative;
  color: #A4A4A4;
}

.modal-tab .nav-link.active {
  color: #618FCA;
  font-family: 'GeneralSans-Medium';
}

.modal-tab .nav-link.active::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 20px;
  border-radius: 3px;
  height: 3px;
  background: #618FCA;
}

.tab-client-single {
  border-radius: 12px;
  border: 1px solid var(--primary);
  margin-top: 15px;
  padding: 15px;
  text-align: center;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tab-client-img {
  width: 51px;
  height: 51px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.tab-client-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tab-client-single h6 {
  padding: 15px 0 0 0;
}

.tab-client-single p {
  font-size: 13px;
}

.streame-list ul {
  display: flex;
  align-items: center;
  margin: 0 -15px;
}

.streame-list ul li {
  padding: 0 15px;
}

.streame-list-img {
  height: 100px;
  width: auto;
  border-radius: 12px;
  overflow: hidden;
}

.streame-list-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.streame-list-single {
  margin-top: 15px;
}

.streame-list-single h6 {
  padding: 15px 0 0 0;
  text-align: center;
}

.trending-details {
  margin-top: 30px;
}

.trending-details-single {
  border-radius: 30px;
  background: var(--white);
  padding: 30px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trending-left {
  width: 154px;
}

.trending-img {
  width: 154px;
  height: 200px;
  border-radius: 12px;
  overflow: hidden;
}

.trending-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.trending-right {
  width: calc(100% - 154px);
  padding-left: 30px;
}

.trending-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.trending-title ul li {
  display: inline-block;
  margin-left: 10px;
}

.trending-title ul li img {
  width: 25px;
}

.trending-meta {
  margin-top: 40px;
}

.trending-meta ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.trending-meta ul li {
  width: 33.33%;
  padding: 0 10px;
  text-align: center;
  border-left: 1px solid #A4A4A4;
}

.trending-meta ul li:first-child {
  border: none;
}

.privacy-block {
  border-radius: 20px;
  background: var(--white);
  padding: 30px;
  margin-top: 30px;
}

.privacy-block h3 {
  font-size: 20px;
}

.privacy-block p {
  padding-top: 25px;
  color: #3F3F3F;
}

.help-banner {
  padding: 80px 0;
  text-align: center;
}

.help-block-single {
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  background: var(--white);
  border-radius: 30px;
  padding: 50px 30px;
}

.help-icon {
  color: var(--black);
  font-size: 50px;
}

.help-block-single h3 {
  padding-top: 30px;
}

.help-block-single p {
  padding: 15px 0 0 0;
}

.help-bottom {
  text-align: center;
  padding: 100px 0;
  margin-top: 100px;
}

.help-bottom p {
  padding-top: 20px;
}

.upgrade-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.upgrade-left {
  width: 370px;
  border-radius: 20px;
  background: var(--white);
  padding: 30px 0;
}

.upgrade-right {
  width: calc(100% - 370px);
  padding-left: 40px;
}

.upgrade-left .nav-link {
  border-radius: 0 !important;
  color: #424242;
  font-weight: 500;
  padding: 12px 30px;
}

.upgrade-left .nav-link.active {
  background: #F0F5FF;
  color: #424242;
}

.upgrade-right-in {
  border-radius: 20px;
  background: var(--white);
  padding: 50px;
  min-height: 100%;
}

.firstTab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upgrade-right .tab-content>.firstTab.active {
  display: flex;
}

.Calendar-outer .fc-toolbar-chunk {
  flex: 1;
}

.Calendar-outer .fc-toolbar-chunk:first-child .fc-button-group button {
  border-radius: 12px;
  background: #618FCA !important;
  color: #FFF !important;
  border: none !important;
  padding: 12px 12px;
  text-transform: capitalize;
  margin-right: 10px;
}

.Calendar-outer .fc-toolbar-chunk:first-child .fc-button-group button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.Calendar-outer .fc-toolbar-chunk:first-child .fc-today-button {
  display: none;
}

.Calendar-outer .fc-toolbar-chunk:last-child {
  text-align: right;
}

.Calendar-outer .fc-toolbar-chunk:last-child .fc-button-group {
  background: #FAFAFA;
  border-radius: 17px;
  padding: 15px;
}

.Calendar-outer .fc-toolbar-chunk:last-child .fc-button-group button {
  border-radius: 12px;
  background: none !important;
  color: #000000 !important;
  border: none !important;
  padding: 15px 25px;
  text-transform: capitalize;
}

.Calendar-outer .fc-toolbar-chunk:last-child .fc-button-group button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.Calendar-outer .fc-toolbar-chunk:last-child .fc-button-group button.fc-button-active {
  background: #618FCA !important;
  color: #FFF !important;
}

.Calendar-outer .fc-col-header th {
  background: #F0F5FF;
  color: #223F7F;
  padding-top: 25px;
  padding-bottom: 25px;
  font-weight: 400;
}

.Calendar-outer .fc-theme-standard td {
  color: #263238;
  background: #FAFAFA;
}

.Calendar-outer .fc .fc-daygrid-day-number {
  color: #263238;
}

/* .Calendar-outer .fc-scrollgrid{ border: none; border-radius: 20px !important;} */
.Calendar-outer .fc-toolbar-title {
  text-align: center;
}

.topic-list ul {
  padding-top: 15px;
}

.topic-list ul li {
  display: inline-block;
  margin: 5px 5px 0 0;
  border: 1px solid var(--primary);
  color: var(--primary);
  padding: 8px 20px;
  border-radius: 50px;
}


.login-head {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
}

.login-wrap.alt .login-head {
  background: #FFF;
  padding-top: 12px;
  padding-bottom: 12px;
}

.site-wrap {
  padding-top: 80px;
}

.site-wrap .login-head {
  background: #FFF;
  padding-top: 12px;
  padding-bottom: 12px;
}

.login-wrap {
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}

.login-wrap.alt {
  background: #FAFAFA
}

.login-left {
  width: 50%;
  min-height: 100vh;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../src/assets/images/banner2.png') left bottom no-repeat var(--secondary);
}

.login-left-img {
  max-width: 420px;
}

.login-right {
  width: 50%;
}

.login-right-main {
  max-width: 600px;
  margin: 0 auto;
}

.login-logo {
  width: 200px;
}

.welcome-txt {
  max-width: 500px;
  color: var(--white);
}

.welcome-txt h2 {
  font-size: 47px;
  padding-bottom: 20px;
  color: var(--white);
}

.login-right-main h2 {
  color: var(--secondary);
}

.login-wrap .common-input-wrap label {
  color: var(--black);
}

.help-video {
  margin-top: 150px;
}

.help-video-single {
  border-radius: 30px;
  background: var(--white);
  padding: 20px;
  margin-top: 30px;
}

.video-banner {
  width: 100%;
  height: 170px;
  border-radius: 15px;
  overflow: hidden;
}

.video-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.help-video-single p {
  color: var(--black);
  padding-top: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.loginFooter {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

.site-footer-in p {
  padding: 0;
  font-size: 14px;
}

.site-footer ul li a {
  font-size: 14px;
}

.loadingWrap {
  position: relative;
  overflow: hidden;
  z-index: 999;
}

.loadingWrap-cont {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingWrap-cont p {
  text-align: center;
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
}

.loading-back {
  background: var(--off-white);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: translateY(-50px);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--secondary);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--secondary) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-spin {
  display: inline-block;
  -webkit-animation: spin 1.5s infinite linear;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.css-13cymwt-control,
.css-t3ipsp-control {
  border: 1px solid var(--off-white) !important;
  background: var(--off-white) !important;
  border-radius: 16px !important;
  padding: 11px 30px !important;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px var(--secondary) !important;
}

/* .css-13cymwt-control:focus, .css-t3ipsp-control :focus{ box-shadow: 0 0 0 1px var(--primary);} */
/* .css-t3ipsp-control:hover{ border: none !important;}
.css-13cymwt-control:hover{ border: none !important; } */


.full-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(0.5rem);
  text-align: center;
}

.full-loader h2 {
  color: var(--primary);
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid var(--secondary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}



.video-modal .modal-body {
  padding: 20px;
}

.video-modal .modal-dialog {
  max-width: 850px !important;
}

.modalClose {
  position: absolute;
  right: 0;
  top: -50px;
  color: var(--white);
  font-size: 30px;
  cursor: pointer;
}


.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .sliderN {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .sliderN:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.sliderN {
  background-color: var(--primary);
}

.switch input:focus+.sliderN {
  box-shadow: 0 0 1px var(--primary);
}

.switch input:checked+.sliderN:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.switch .sliderN.round {
  border-radius: 34px;
}

.switch .sliderN.round:before {
  border-radius: 50%;
}



.sound-control {
  border-radius: 15px;
  background: #FAFAFA;
  padding: 15px;
}

.voice-record-time {
  font-weight: 600;
  color: var(--black);
  font-size: 20px;
}

.rec-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 0 0;
}

.record-img {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: var(--white);
  margin: 0 5px;
}

body.modal-open {
  padding: 0 !important;
}

.info-msg {
  text-align: center;
  background: var(--secondary);
  color: var(--white);
  border-radius: 16px;
  padding: 15px;
}


.switch-label {
  display: flex;
  align-items: center;
}

.switch-stat {
  padding-left: 10px;
}


.meme-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.meme-list li {
  width: 20%;
  padding: 0 10px;
  margin-bottom: 20px;
}

.meme-list li .list-images-single {
  height: 300px !important;
}

.meme-list li .list-images-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media (max-width: 1800px) {
  .container {
    padding: 0 70px;
  }

  .container.sm {
    max-width: 100% !important;
  }
}

@media (max-width: 1599px) {
  .webinar {
    padding: 210px 0;
    margin-top: 0;
  }

  .webinar .container {
    transform: translateY(14px);
  }

  .container {
    padding-left: 50px;
    padding-right: 50px;
  }

  .login-right-main {
    max-width: 500px;
  }

  .welcome-txt h2 {
    font-size: 47px;
  }
}

@media (max-width: 1199px) {
  .site-nav {
    padding-left: 70px;
  }

  .video-listing-img {
    height: 250px;
  }

  .video-listing-bottom {
    padding: 20px;
  }

  .video-listing-bottom h3 {
    font-size: 17px;
  }

  .video-listing-bottom p {
    font-size: 14px;
  }

  .webinar {
    padding: 134px 0;
  }

  .stats {
    flex-wrap: wrap;
    justify-content: center;
  }

  .stats-single {
    width: 33.33%;
    margin-top: 30px;
  }

  /* .login-wrap {
    flex-wrap: wrap;
    display: block;
  }

  .login-left {
    width: 100%;
  }

  .login-right {
    width: 100%;
  }

  .login-left {
    height: auto;
    min-height: 50px;
    padding: 50px 0;
  } */

  /* .login-head {
    position: static;
    background: var(--secondary);
  } */


  .login-left,
  .login-right {
    padding: 0 50px;
  }

  .login-right-main {
    padding: 50px 0;
  }

  .site-footer-in p {
    font-size: 11px;
  }

  .site-footer-in {
    flex-wrap: wrap;
  }

  .footer-nav {
    width: 100%;
    text-align: center;
  }

  .footer-single {
    width: 100%;
    text-align: center;
  }

  .footer-logo {
    width: 100%;
    text-align: center;
  }

  .site-footer-in {
    height: auto;
    padding: 15px 0;
  }

  .footer-single {
    padding: 15px 0;
  }

  .hero-banner-video iframe {
    transform: none;
  }

  .hero-banner {
    height: auto;
  }

  /* .site-footer ul li a {
    font-size: 11px;
  } */

  .meme-list li {
    width: 33.33%;
  }

  .meme-list li .list-images-single {
    height: 200px !important;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .cont-box {
    padding: 30px;
  }

  .loginFooter {
    position: static;
  }
}

@media (max-width: 1023px) {


  .login-wrap {
    flex-wrap: wrap;
    display: block;
  }

  .login-left {
    width: 100%;
  }

  .login-right {
    width: 100%;
  }

  .login-left {
    height: auto;
    min-height: 50px;
    padding: 50px 0;
  }

  .login-head {
    position: static;
    background: var(--secondary);
  }

  .login-wrap {
    min-height: 30px;
  }


  .hero-banner {
    height: auto;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .hero-banner {
    min-height: 400px;
  }

  .hero-banner-txt h1 {
    font-size: 40px;
  }

  .hero-banner-txt p {
    font-size: 14px;
    padding: 0;
  }

  .stats {
    margin-top: -70px;
  }

  .recent-video,
  .image-block-single {
    margin-top: 50px;
  }

  .site-footer ul li {
    margin-left: 20px;
  }

  .site-nav {
    margin: 0;
    padding: 0;
    position: fixed;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100vh;
    background: var(--secondary);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease;
  }

  .site-nav.act {
    left: 0;
  }

  .site-nav ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .site-nav ul li {
    margin: 5px 0 !important;
  }

  .site-nav ul li a {
    font-size: 22px;
  }

  .video-listing-img {
    height: 190px;
  }

  .play-icon {
    font-size: 50px;
  }

  .video-listing-single {
    border-radius: 15px;
  }

  .video-listing-img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .webinar {
    padding: 90px 0;
  }

  .stats-in {
    border-radius: 15px;
  }

  .mobClick {
    display: block;
  }

  .logo {
    display: flex;
    align-items: center;
  }

  .video-listing-single {
    transform: none !important;
  }

  .video-listing-img img {
    transform: none !important;
  }

  .cont-box {
    padding: 30px;
  }

  .site-footer-in {
    flex-wrap: wrap;
    height: auto;
    padding: 15px 0;
  }

  .footer-single {
    width: 100%;
    text-align: center;
  }

  .footer-nav {
    width: 100%;
    text-align: center;
  }

  .site-nav>ul>li>a {
    color: #FFF;
  }
}

@media (max-width: 767px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .stats {
    margin-left: -4px;
    margin-right: -4px;
  }

  .stats-single {
    width: 100%;
    padding: 0 4px;
    margin-top: 15px;
  }

  .stats-in {
    padding: 15px;
    border: 1px solid var(--primary);
  }

  .hero-banner-txt p {
    font-size: 15px;
  }

  .hero-banner-txt h1 {
    font-size: 54px;
  }

  .header-main {
    height: 60px;
  }

  .image-block-single:nth-child(even) [class*='col-'] {
    order: 1;
  }

  .image-block-single:nth-child(even) [class*='col-']+[class*='col-'] {
    order: 2;
  }

  .image-block-txt {
    padding: 40px 0 0 0;
  }

  .site-footer-in {
    display: block;
    text-align: center;
    height: auto;
    padding: 20px 0;
  }

  .footer-nav {
    padding: 5px 0 0 0;
  }

  .site-footer-in {
    font-size: 14px;
  }

  .site-footer ul li {
    margin: 0 5px !important;
  }

  .cont-box {
    padding: 15px;
    border-radius: 15px;
  }

  .common-input {
    padding: 10px 15px;
    border-radius: 5px;
  }

  .trending-details-single {
    padding: 15px;
    border-radius: 15px;
  }

  .trending-details-single {
    flex-wrap: wrap;
  }

  .trending-left {
    width: 100%;
  }

  .trending-right {
    width: 100%;
    padding: 20px 0 0 0;
  }

  .welcome-txt h2 {
    font-size: 40px;
  }

  .login-left {
    padding: 40px 15px;
  }

  .login-right-main {
    padding-left: 15px;
    padding-right: 15px;
  }

  .login-right-main h2 {
    font-size: 25px;
  }

  .loginFooter {
    position: static;
  }

  .meme-list li {
    width: 50%;
  }

  .meme-list li .list-images-single {
    height: 100px !important;
  }

  .login-right {
    padding: 0px 5px;
  }
}


.video-scripts .active {
  background: #b2caee;
}

.canvasEditor-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #000000b5;
}

.video-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  width: 100% !important;
  height: 100%;
  object-fit: contain;
  display: block;
}

.layer-border {
  border: 4px dashed #4F94C9;
  padding: 3px;
}

.zoominheader {
  background-size: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-animation: zoomin 10s linear infinite;
  animation: zoomin 10s linear infinite;
  transition: all .5s linear;
  overflow: hidden;
}

.zoomoutheader {
  width: 100%;
  background-size: cover;
  -webkit-animation: zoomout 10s linear infinite;
  animation: zoomout 10s linear infinite;
  transition: all .5s linear;
  overflow: hidden;
}

.zoomleft {
  width: 100%;
  background-size: cover;
  -webkit-animation: zoomout 50s linear infinite;
  animation: zoomleft 50s linear infinite;
  transition: all .5s linear;
  overflow: hidden;
}


@-webkit-keyframes zoomleft {
  0% {
    transform: translateX(0%) scale(1.5);
  }

  100% {
    transform: translateX(100%) scale(1);
  }
}

@keyframes zoomleft {
  0% {
    transform: translateX(0%) scale(1.5);
  }

  100% {
    transform: translateX(100%) scale(1);
  }
}

.zoomright {
  width: 100%;
  background-size: cover;
  -webkit-animation: zoomout 50s linear infinite;
  animation: zoomleft 50s linear infinite;
  transition: all .5s linear;
  overflow: hidden;
}


@-webkit-keyframes zoomright {
  0% {
    transform: translateX(-0%) scale(1.5);
  }

  100% {
    transform: translateX(-100%) scale(1);
  }
}

@keyframes zoomright {
  0% {
    transform: translateX(-0%) scale(1.5);
  }

  100% {
    transform: translateX(-100%) scale(1);
  }
}

/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(2);
  }
}

@keyframes zoomin {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(2);
  }
}

/*End of Zoom in Keyframes */


@-webkit-keyframes zoomout {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomout {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

/*End of Zoom out Keyframes */


.editor-background-img {
  height: 100% !important;
  object-fit: contain;
}

.css-for-svgs {
  height: 100% !important;
  width: 100% !important;
  padding: 2px;
}

.hover-video-player video {
  height: 100% !important;
}

/* trends css  here */

.youtube-content h5 {
  /* text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical; */
  font-size: 14px !important;
}

.youtube-content span {
  font-size: 13px;
}

.youtube-content span a {
  text-decoration: none;
}

.youtube-content {
  padding: 10px;
  background-color: #eee;
}

.youtube-content p {
  font-size: 13px;
  margin-top: 10px;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 3;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  display: -webkit-inline-box;
  font-weight: 600;
}

.trands-table-box table {
  border-radius: 0px !important;
}

.user-data .theadbgcolor {
  background-color: var(--secondary-color);
}


.tranding-post-sec .theadbgcolor {
  background-color: var(--secondary-color);
  color: #fff;
}

.table {
  background-color: #f7f8fc;
  border-top-left-radius: 5px !important;
}


.table thead th {
  border-bottom: 0 solid #dee2e6 !important;
  padding: 15px 4px;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  vertical-align: middle;
}

.hide-button {
  pointer-events: none;
}

.client-img-opacity {
  opacity: 0.5 !important;
}

.disable-social-img {
  filter: grayscale(100%);
}

/* .editor-bar.Image-editor-bar{
  display: none!important;
} */

.fc-daygrid-day:hover .fa-custom.fas.fa-plus-circle {
  display: block;
}

.fc-daygrid-day-number {
  width: 100%;
}

.fa-custom.fas.fa-plus-circle {
  position: absolute;
  bottom: -170%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
}

.schedule_modal {
  height: 345px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
}

.addReelBt {
  position: absolute;
  right: -50px;
  top: 50%;
  margin-top: -17px;
  cursor: pointer;
}

.addReelBt svg {
  font-size: 40px;
  color: #838383;
}

.addReelBt svg:hover {
  color: #b2caee;
}

.tabepaneIn-main {
  background: #FAFAFA;
  border: 2px solid #FAFAFA;
  border-radius: 22px;
  padding: 40px;
}

.tabepaneIn-cont {
  background: #f1f7ff;
  border-radius: 10px;
  overflow: hidden;
  padding: 0 30px 30px;
}

.upgrade-para p {
  color: #000 !important;
  font-size: 19px;
}

.tabepaneIn-cont h3 {
  background: #fff;
  font-size: 18px;
  color: #000 !important;
  margin-bottom: 30px;
  margin-left: -30px;
  margin-right: -30px;
  padding: 15px 0 15px 30px;
}

.upgrade-para {
  padding: 10px;
}

.tabepaneIn-cont-alt {
  padding-top: 40px;
}

.upgrade-content {
  padding: 20px 30px;
}

.upgrade-box-content {
  background-color: #f1f7ff;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-heading h6 {
  background: #fff;
  color: #000 !important;
  padding: 10px;
}

/* .upgrade-box-content button {
  background: #000;
  border: none;
  border-radius: 30px;
  color: #fff;
  padding: 7px 30px;
} */

.upgrade_height {
  height: 100% !important;
}

.fb-card .youtubeicon {
  font-size: 30px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-60%, -30%);
  transform: translate(-60%, -30%);
}

.VideoModal.show-modal {
  display: block;
}

.integr-single {
  align-items: center;
  background: #fff;
  border: 1px solid #4fc5e1;
  border-radius: 15px;
  display: flex;
  height: 240px;
  justify-content: center;
  margin-top: 30px;
  padding: 30px;
  position: relative;
  text-align: center;
}

.connection-box-main {
  display: block;
}

.int_head {
  margin-top: 60px;
}

.integr-del {
  color: red;
  font-size: 20px;
  opacity: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  visibility: hidden;
}

.tag-connected {
  background: #4fc5e1;
  border-radius: 50px;
  bottom: -14px;
  color: #fff;
  font-size: 14px;
  left: 50%;
  padding: 4px 18px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.youtube-Wrap h6 {
  color: #000;
  font-size: 16px;
  margin-top: 10px;
}

.youtube-Wrap p {
  color: #000;
  font-size: 12px;
  font-weight: 600
}

.site-link.integr_card::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: #918d8d8a;
  transition: all 0.5s ease;
}

.site-link.integr_card:hover::before {
  width: 100%;
}

.integr-single:hover .integr-del {
  visibility: visible;
  opacity: 1;
}


.upload-scroll-remove::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.upload-scroll-remove {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.icon-img {
  height: auto;
  padding-left: 3px;
}

.icon-img img {
  height: auto;
  width: 20px;

}

.modalVid {
  background: url('./assets/images/Ai_img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 600px;
}

.theme-modal.ai_img_wrap.video-modal .modal-dialog {
  max-width: 991px;
}

.modalVid {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/images/Ai_img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 600px;
}

.yub_btn {
  border: none;
  background: #0d6efd;
  color: #fff;
  padding: 2px 3px;
  font-size: 11px;
  border-radius: 4px;
  margin-left: 6px;
}