.shape-box .shape-item .circle {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-color: #000;
}

.shape-box .shape-item .triangle {
    background-color: #000;
    width: 61px;
    height: 53px;
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.shape-box .shape-item2 a,
.shape-box .shape-item a {
    width: 46px;
    height: 46px;
    background-color: #000;
    display: inline-block;
    margin: 10px;
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    border: 2px solid #000;
    display: inline-block;
    margin: 10px;
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    border: 2px solid #000;
    display: inline-block;
    margin: 10px;
}

.shape-box .shape-item2-border a {
    border-radius: 5px;
}

.shape-box .shape-item2 a {
    border-radius: 5px;
}

.shape-box .shape-item3 a {
    width: 66px;
    height: 46px;
    background-color: #000;
    display: inline-block;
    margin: 10px;
}

.shape-box .shape-item3-border a {
    width: 66px;
    height: 46px;
    border: 2px solid #000;
    display: inline-block;
    margin: 10px;
}

.shape-box .shape-item .cliped-circle {
    width: 55px;
    height: 55px;
    background-color: #000;
    border-radius: 50%;
    position: relative;
}

.shape-box .shape-item .cliped-triangle,
.shape-box .shape-item .cliped-triangle:before {
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.shape-box .shape-item .cliped-triangle {
    background-color: #000;
    width: 61px;
    height: 53px;
    position: relative;
}

.shape-box .shape-item .cliped-circle:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    bottom: 0;
    background-color: #ffffff;
    width: 49px;
    height: 49px;
    border-radius: 50%;
}

.shape-box .shape-item .cliped-triangle:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 6px;
    right: 0;
    bottom: 0;
    width: 49px;
    height: 45px;
    background-color: #fffcfc;
}


.icon-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.emoji-custom {
    margin-right: 10px;
    margin-bottom: 10px;

}

.icon-items .emoji-custom i {
    font-size: 35px;
    cursor: pointer;

}

.icon-items .emoji-custom span {
    font-size: 35px;
    cursor: pointer;
}

.emoji_custom_wrapper {
    width: 100%;
    height: 450px !important;
    overflow-y: scroll;
    overflow-x: hidden;
    background: transparent;
    padding: 10px;
}


.border-style {
    display: block;
    min-height: 50px;
    margin-bottom: 45px;
    color: #222;
}

.solid-border {
    border: 3px solid #222;
}

.dashed-border {
    border: 3px dashed #222;
}

.dashed-border-light {
    border: 2px dashed #222;
}

.dotted-border {
    border: 3px dotted #222;
}

.add-layer .layer {
    width: 100%;
    height: 40px;
    background-color: #000;
}

.swatch {
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
    width: 100%;
    padding: 10px;
}

.swatch-color {
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.swatch-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.text-picker {
    position: relative;
}

.text-picker .sketch-picker {
    position: absolute;
    z-index: 999;
    top: 85px;
    left: 40px;
}

.fetch-loader-block {
    text-align: center;
    height: 100%;
    width: 100%;
}

.fetch-loader-block .fetch-loader {
    color: #618fca;
    font-size: 20px;
    margin-top: 10px;
}

.loader-percent {
    color: rgb(0, 0, 0);
    font-size: 18px;
}

.select-tile-style {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #618fca;
    font-size: 28px;
    border: 2px solid #618fca;
    border-radius: 10px;
}


.loader {
    width: 80px;
    height: 80px;
    border: 4px #618fca solid;
    border-radius: 50%;
    animation: rotation 1s ease-in-out infinite;
    text-align: center;
}

.loader-sec {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader::after {
    width: 20px;
    height: 20px;
    background-color: #618fca;
    border-radius: 100%;
    position: absolute;
    content: "";
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.active-music-file {
    background: #f0f5ff !important;
    border: 1px solid #618fca;
}

.transition-txt {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-editor .editor_9X16 {
    width: 360px !important;
    height: 640px !important;
    background: none !important;
    border-radius: 0 !important;
    margin: 0 auto;
    overflow: hidden;
}

.video-editor .editor_16X9 {
    width: 640px !important;
    height: 360px !important;
    background: none !important;
    border-radius: 0 !important;
    margin: 0 auto;
    overflow: hidden;
}

.video-editor .editor_1X1 {
    width: 460px !important;
    height: 460px !important;
    background: none !important;
    border-radius: 0 !important;
    margin: 0 auto;
    overflow: hidden;
}

.editor-bar_1X1 {
    margin-top: 0 !important;
}

.video-editor .canvasEditor-main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background: #000;
    padding-top: 0;
}

.active-tile-border {
    border: 2px solid #618fca
}

.deactive-tile-border {
    border: 1px solid #888888
}

.IconDel {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2c3450;
}

.micON {
    position: absolute;
    top: 4px;
    left: 4px;
}

.no-showvideotag {
    visibility: hidden;
    height: 0px;
    width: 0px;
}



.logo-style {
    z-index: 2;
    max-width: 100px;
}

.watermark-op {
    opacity: .5;
}

.logo-top-left {
    position: absolute !important;
    top: 0;
    left: 0;
    padding: 10px;
}

.logo-top-right {
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 10px;
}

.logo-bottom-right {
    position: absolute !important;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.logo-bottom-left {
    position: absolute !important;
    bottom: 0;
    left: 0;
    padding: 10px;
}

.logo-top-center {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.logo-center-left {
    position: absolute !important;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 10px;
}

.logo-center {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

.logo-center-right {
    position: absolute !important;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 10px;
}

.logo-bottom-center {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.loader-center-temp1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #618fca;
    height: 100%;
}


.media-loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #618fca;
    font-size: 25px;
}

.editor_slide_bg {
    background: #f3f3f3;
    padding: 10px 50px;
    border-radius: 10px;
    position: relative;
}

.video-slide ul li .editorReel-single-li {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 79px !important; */
    margin-right: 9px;
    width: 175px;
    height: 95px;
    background: #121521;
    border: 2px solid #454545;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.video-slide ul {
    display: flex;
    white-space: nowrap;
    position: relative;
}

.video-slide ul li {
    display: flex;
    align-items: end !important;
}

.video-slide ul li:first-child {
    margin-left: 0;
}

.video-slide ul li .Sub-editorReel-single-li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
    width: 150px;
    height: 75px;
    background: #121521;
    border: 2px solid #454545;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.video-slide ul li .Sub-editorReel-single-li .editorReel-single {
    width: auto;
    height: 100%;
    background: #101010;
    position: relative;
    color: #FFF;
    text-align: center;
    overflow: hidden;
}

.progress-percent {
    position: absolute;
    top: -4px;
    left: 50%;
    color: black;
    font-weight: bold;
}

.inner-tab .nav {
    justify-content: space-between;
}

.editor-bar {
    background: #b2caee !important;
    margin: 4px 0;
}

.whiteFont {
    color: #fff;
}

.whiteFont:hover {
    color: #618fca;
}

.image-editor-img_square {
    width: 540px;
    margin: 0 auto;
    position: relative;
}

.image-editor-img_varticle {
    width: 360px;
    margin: 0 auto;
    position: relative;
}

.image-editor-img_regular {
    width: 768px;
    margin: 0 auto;
    position: relative;
}

.footer-single p {
    font-size: 14px;
}

.common-input.large {
    height: 540px !important;
}


@media (max-width: 1380px) {
    .video-editor .editor {

        transform: scale(0.8);
        position: relative;
        left: -6%;
    }

    /* .editor-bar {
        margin-top: -35px;
    } */

    .Image-editor-bar {
        margin-top: 4px !important;
    }

    .editor-settings {
        border-radius: 20px;
        background: var(--white);
        padding: 8px;
        margin-top: 7px;
    }
}

.fc-daygrid-day-events {
    /* text-align: center !important; */

}

.fc-daygrid-event-harness {
    min-width: 100px;
    margin: 5px;
    background: #618fca;
    border-radius: 5px;
    padding: 1px;
}

.fc-daygrid-event {
    color: #ffffff !important;
}

.fc-event-time {
    color: #ffffff !important;
}

/* .fc-h-event {
    min-width: 120px !important;

} */

.fc-daygrid-event-dot {
    display: none;
}

.schedule-details {
    padding: 10px;
}

.schedule-details h6 {
    padding: 5px;
    display: flex;

}

.schedule-details strong {
    /* padding-right: 50px; */
    padding-right: 5px;
}

.sketch-background-ui .sketch-picker {
    width: 278px !important;
}

.theme-modal.xl.schedule_popup .modal-dialog {
    max-width: 70%;
    margin: 0 auto;
}

.schedule_popup .modal-dialog-scrollable .modal-content {
    overflow: hidden;
    height: 88%;
}

.schedule_popup .schedule_modal {
    height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
}